export const content = [{
    slug: 'harga-undangan-pernikahan-mewah',
    content: `
                <h1>
                    Harga Undangan Pernikahan Mewah
                  </h1>
                  <p>
                  <b>Harga Undangan Pernikahan Mewah</b> - Pernikahan merupakan suatu hal yang sakral yang diidam-idamkan hampir semua orang di dunia. Tentunya semua orang menginginkan pernikahanya sempurna. Salah satu hal yang perlu diperhatikan dalam acara pernikahan yakni undangan. Undangan pernikahan memiliki fungsi untuk menyebarkan informasi pelaksanaan pernikahan mempelai, seperti lokasi, tanggal, dan nama mempelai. Oleh karena itu undangan pernikahan merupakan salah satu hal yang sangat penting dalam suatu acara pernikahan.
                    </p>
                    <p>

                    Di era modern sekarang ini tentu sangat banyak pilihan undangan yang kamu pilih dengan kelebihan dan kekurangan masing-masing baik dilihat dari harga maupun efektifitas Undangan tersebut.
                    </p>
                    <h2>
                    Harga Undangan Pernikahan Mewah
                    </h2>
                    <h3>

                    1. Undangan Hard Cover
                    </h3>
                    <div class='row justify-content-center'>
                    <div class='p-1 col-md-6 col-8'>
                      <img class='img-fluid rounded' src='https://cf.shopee.co.id/file/5680486aa4838941302d01a716c10ec5'/>
                    </div>
                    </div>
                    <p>
                    Undangan yang satu ini merupakan jenis undangan yang dicetak diatas kertas karton ataupun jenis lainnya yang berbahan tebal. Undangan ini lebih tahan lama dibandingkan jenis undangan lainnya.
                    <br/><br/>
                    Kamu dapat memilih undangan hard cover dengan hot print, laminating glossy ataupun emboss.
                    <br/><br/>
                    Namun yang perlu diperhatikan adalah harga dari undangan ini cukup mahal, yakni sekitar Rp 5500 hingga Rp 11.150 per buah
                    <br/><br/>
                    Jadi bisa dibayangkan kan ketika anda mencetak 100 atau bahkan 1000 undangan, berapa rupiah yang harus anda keluarkan.
                    <br/><br/>
                    </p>
                    <h3>
                    2. Undangan Soft Cover
                    </h3>
                    <div class='row justify-content-center'>
                    <div class='p-1 col-md-6 col-8'>
                      <img class='img-fluid rounded' src='https://ecs7.tokopedia.net/img/cache/700/product-1/2019/2/2/2251974/2251974_ba51d0e9-c608-4fa1-a51b-7a54677e9fd4_791_791.jpg'/>
                    </div>
                    </div>
                    
                    <p>
                    Undangan kedua yakni Undangan dengan jenis soft cover, undangan ini memiliki bahan yang lebih tipis dibandingkan dengan jenis har cover.
                    <br/><br/>
                    Jenis ini biasanya paling banyak dipilih calon penganten dikarenakan harganya cukup murah dibandingkan dengan jenis undangan lainnya dan lama pembuatannya juga cukup cepat. Beberapa percetakan menawarkan pilihan seperti laminasi doff 1 muka dan 2 muka.
                    <br/><br/></p>
                    <h3>
                    3. Undangan Digital Berbasis Website</h3>
                    <div class='row justify-content-center'>
                    <div class='p-1 col-md-6 col-8'>
                      <img class='img-fluid rounded' src='https://undanganku.me/static/gambar1-a5140274f7dd6bf3e7acefa8215690f8.png'/>
                    </div>
                    </div>
                    <p>
                    Undangan di era modern ini yang cukup diminati juga adalah undangan digital berbasis website, 
                    pasalnya anda hanya cukup membayar dengan biaya terjangkau diawal kemudian dapat membagikannya 
                    kesiapa saja yang ingin anda undangan ke pesta pernikahan anda.
                    <br/><br/>
                    Anda juga dapat menambahkan banyak fitur yang tentunya tidak bisa di tuangkan ke media kertas. 
                    Misalnya video pre wedding, dokumentasi, countdown, form buku tamu, dan kamu juga bebas request 
                    fitur yang kamu inginkan. <a href='https://undanganku.me'><b>Undanganku.me</b></a> sendiri merupakan salah satu jasa penyedia undangan digital berbasis website dengan harga terjangkau. Promo gratis hosting dan bebas revisi merupakan kelebihan yang ditawakran.
                    <br/><br/>

                    Jadi tunggu apalagi sempurnakan pernikahanmu dengen Undangan Digital Berbasis Website terbaik
                    </p>
        `
}]