import React, { Component } from 'react'
import { Image } from '../../components/blog';
import { content } from '../../components/content';
import Container from 'react-bootstrap/Container'
import { Form, Row, Col, Button } from 'react-bootstrap'
import '../../style/blog/style.css'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helm } from '../../components/header'
import logo from '../../assets/img/logo.ico'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
 

export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content:''
    }
  }
  static getInitialProps({query}){
    return {
        query: query
    }
}
useQuery() {
  return new URLSearchParams(window.location.search);
}

  componentDidMount() {
  
    let query = this.useQuery().get('article'); 
    query=query.replace('/','')
    
    let finder=content.find(val=>{
      return val.slug==query
    })
    this.setState({content:finder?finder.content:''})
  }
  Komentar = () => {

    let disqusConfig = {
      url: `https://undanganku.me${this.props.location.pathname}`,
        identifier: `${this.props.location.pathname}`,
        title: 'harga-undangan-pernikahan-mewah',
    }
    return (
      <>
        <CommentCount config={disqusConfig} placeholder={'...'} />
        <Disqus config={disqusConfig} />
      </>
    )
  }

  render() {
    let {content}=this.state
    

    return (
      <>
      <Helm
          title='Harga Undangan Pernikahan Mewah'
          desc="Undangan berbasis online harga mulai 100 ribuan, responsive, interaktif, dan modern"
          logo={logo}
        />
        <div id='blog'>
          <Container id='b-header' className='p-3' fixed fluid>
            <Col md={4} className='b-title'>
              Undanganku.me
            </Col>
          </Container>
          <Container fluid >
            <Row className='justify-content-center text-justify' className='p-md-3 m-md-3 mt-3 '>
              <Col md={8} xs={12}>
                <div id='isi' className='p-md-3 p-3 mb-3'>
                  {
                    ReactHtmlParser(content)
                  }
                </div>
                {
                  this.Komentar()
                }
              </Col>
              <Col md={4} xs={12}>
                <div id='isi' className='p-3'>
                  Coming Soon
            </div>

              </Col>
            </Row>
          </Container>
        </div>
      </>

    )
  }
}